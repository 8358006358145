// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import "mdn-polyfills/Node.prototype.firstElementChild";

import parseXML from "xml-parse-from-string";

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars           as fasFaBars           } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCaretDown      as fasFaCaretDown      } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft      as fasFaCaretLeft      } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight     as fasFaCaretRight     } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCalendar       as fasFaCalendar       } from "@fortawesome/pro-solid-svg-icons/faCalendar";
import { faCircle         as fasFaCircle         } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faClock          as fasFaClock          } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment        as fasFaComment        } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faEnvelope       as fasFaEnvelope       } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faFolder         as fasFaFolder         } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faQuestionCircle as fasFaQuestionCircle } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faSearch         as fasFaSearch         } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faTag            as fasFaTag            } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faUserCircle     as fasFaUserCircle     } from "@fortawesome/pro-solid-svg-icons/faUserCircle";

import facFaBriefcase from "../../media/fac-briefcase.svg";
import facFaHandshake from "../../media/fac-handshake.svg";
import facFaPuzzle    from "../../media/fac-puzzle.svg";

/**
 * Add solid icons
 */
library.add(fasFaBars, fasFaCaretDown, fasFaCaretLeft, fasFaCaretRight, fasFaCalendar, fasFaCircle, fasFaClock, fasFaComment, fasFaEnvelope, fasFaFolder, fasFaQuestionCircle, fasFaSearch, fasFaTag, fasFaUserCircle);

/**
 * Add custom icons
 */
library.add({
    "briefcase": {
        iconName: "briefcase",
        prefix: "fac",
        icon: [
            facFaBriefcase.attributes.viewBox.split(" ")[2],
            facFaBriefcase.attributes.viewBox.split(" ")[3],
            [],
            "e000",
            parseXML(facFaBriefcase.content).firstElementChild.getAttribute("d"),
        ]
    },
    "handshake": {
        iconName: "handshake",
        prefix: "fac",
        icon: [
            facFaHandshake.attributes.viewBox.split(" ")[2],
            facFaHandshake.attributes.viewBox.split(" ")[3],
            [],
            "e001",
            parseXML(facFaHandshake.content).firstElementChild.getAttribute("d"),
        ]
    },
    "puzzle": {
        iconName: "puzzle",
        prefix: "fac",
        icon: [
            facFaPuzzle.attributes.viewBox.split(" ")[2],
            facFaPuzzle.attributes.viewBox.split(" ")[3],
            [],
            "e002",
            parseXML(facFaPuzzle.content).firstElementChild.getAttribute("d"),
        ]
    },
});

/**
 * Watch the DOM to insert icons
 */
dom.watch();
